import React from "react";
import { Image } from "react-bootstrap";
import { useHistory } from "react-router";
import {
  AboutUsURL,
  ContactUsURL,
  PrivacyTwoURL,
  PrivacyURL,
  RechargeURL,
  RefundPolicyURL,
  ShippingDelURL,
  SuccesStoriesURL,
} from "../../configs";
import "../../css/footer.css";
import logo from "../../images/ashtavivah/SVG/WEB.svg";

const websiteName = process.env.REACT_WEBSITE_NAME || "ashtvivah.com";
const FooterComponent = () => {
  const history = useHistory();
  return (
    <footer className="footer">
      <div className="container text-black my-1 fbg bg-light">
        <div className="row pt-2 pb-1 px-1 border-top">
          <div className="col-md-6">
            <div className="fs-6 fw-normal text-black footer-logo">
              <Image
                src={logo}
                alt="ashtvivah-Marathi matrimony website"
                className="ashtvivah-logo"
              />
            </div>
            <p className="text-muted pt-2">
              Ashtvivah is a Professional Matrimonywhere professionalism meets
              passion in the realm of matrimony. We pride ourselves on being
              more than just a matrimonial platform; we're your trusted
              companion on the journey to finding your life partner.
            </p>
          </div>
          <div className="col-md-3">
            <div className="fs-6 fw-normal text-black">Discover</div>
            <ul className="nav flex-column pt-2">
              <li className="nav-item">
                <p
                  onClick={() => history.push(AboutUsURL)}
                  className="tdoff p-0 text-muted text-decoration-underline"
                  role="button"
                >
                  About Us
                </p>
              </li>
              <li className="nav-item">
                <p
                  onClick={() => history.push(ContactUsURL)}
                  className="tdoff p-0 text-muted text-decoration-underline"
                  role="button"
                >
                  Contact us
                </p>
              </li>
              <li className="nav-item">
                <p
                  onClick={() => history.push(RechargeURL)}
                  className="tdoff p-0 text-muted text-decoration-underline"
                  role="button"
                >
                  Pricing
                </p>
              </li>
              <li className="nav-item">
                <p
                  onClick={() => history.push(SuccesStoriesURL)}
                  className="tdoff p-0 text-muted text-decoration-underline"
                  role="button"
                >
                  Success Stories
                </p>
              </li>
            </ul>
          </div>
          <div className="col-md-3">
            <div className="fs-6 fw-normal text-black">Resources</div>
            <ul className="nav flex-column pt-2">
              <li className="nav-item mb-2">
                <p
                  className="tdoff p-0 text-muted text-decoration-underline"
                  role="button"
                  onClick={() => history.push(PrivacyURL)}
                >
                  Terms &amp; Conditions
                </p>
              </li>
              <li className="nav-item mb-2">
                <p
                  className="tdoff p-0 text-muted text-decoration-underline"
                  role="button"
                  onClick={() => history.push(PrivacyTwoURL)}
                >
                  Privacy Policy
                </p>
              </li>
              <li className="nav-item mb-2">
                <p
                  className="tdoff p-0 text-muted text-decoration-underline"
                  role="button"
                  onClick={() => history.push(RefundPolicyURL)}
                >
                  Refund Policy
                </p>
              </li>
              <li className="nav-item mb-2">
                <p
                  className="tdoff p-0 text-muted  text-decoration-underline"
                  role="button"
                  onClick={() => history.push(ShippingDelURL)}
                >
                  Shipping &amp; Delivery Policy
                </p>
              </li>
            </ul>
          </div>
        </div>
        <div className="row">
          <div className="col py-2 border-top text-muted text-center">
            Copyright &copy; {new Date().getFullYear()} {websiteName} All Right
            Reserved
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterComponent;
