import axios from "axios";

export default axios.create({
  baseURL: "http://localhost:8080/api",
  headers: {
    "Content-type": "application/json",
  },
});

export const headers = {
  "Access-Control-Allow-Origin": "*",
  "Content-type": "application/json",
};

export const getToken = async () => {
  const { token = "" } = JSON.parse(localStorage.getItem("user")) || {};
  return token;
};

export const tokenAddedHeaders = async () => {
  return {
    headers: {
      ...headers,
      Authorization: "Bearer " + (await getToken()),
    },
  };
};

export const tokenDocumentAddedHeaders = async () => {
  return {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + (await getToken()),
    },
  };
};

export const razorPayHeaders = async (razorpay_signature) => {
  // x - razorpay - signature;
  return {
    headers: {
      ...headers,
      "x-razorpay-signature": razorpay_signature,
      Authorization: "Bearer " + (await getToken()),
    },
  };
};
