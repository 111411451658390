import React, { Suspense, useEffect, useState } from "react";
import { Route, useLocation } from "react-router-dom";
import { NavbarComponent } from "./NavbarComponent";
import PublicRoutes from "../routes/publicRoutes";
import PrivateRoute from "../routes/privateRoutes";
import Loader from "./loader";
import "../css/footer.css";

import {
  initGA,
  useAnalyticsEventTracker,
  routingTrack,
} from "../utils/useAnalyticsEventTracker";
import { useSelector } from "react-redux";
import FooterComponent from "./helpers/footer";

const TRACKING_ID = process.env?.REACT_APP_TRACKING_ID;

export const ViewComponent = () => {
  const location = useLocation();
  const { userIsLoggedIn } = useSelector((state) => state?.user);

  useEffect(() => {
    initGA(TRACKING_ID);
  });

  const [isAuth, setIsAuth] = useState(userIsLoggedIn);

  useEffect(() => {
    if (userIsLoggedIn) {
      setIsAuth(userIsLoggedIn);
    }
  }, [userIsLoggedIn]);

  useEffect(() => {
    routingTrack(location);
  }, [location]);

  return (
    <>
      <NavbarComponent />
      <div className="root">
        <Route>
          {!isAuth ? <PublicRoutes /> : <PrivateRoute />}
          <Suspense fallback={<Loader />}></Suspense>
        </Route>
        <FooterComponent />
      </div>
    </>
  );
};
