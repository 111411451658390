import React from "react";
import asyncComponent from "../hoc/hocComponent";
import {
  LoginURL,
  RechargeURL,
  ForgotPasswordURL,
  ProfileListURL,
  ContactUsURL,
  SuccesStoriesURL,
  RegisterSucceess,
  ROOT,
  WatchPublicProfiles,
  PrivacyURL,
  AboutUsURL,
  PrivacyTwoURL,
  RefundPolicyURL,
  ShippingDelURL,
} from "../configs";
import { Route } from "react-router";
import { v4 as uuidv4 } from "uuid";

// const PageNotFound = asyncComponent(() => import('../views/pageNotFoundComponent'));
const AsyncHome = asyncComponent(() => import("../views/public/home"));
const AsyncProfileListComponent = asyncComponent(() =>
  import("../views/public/freeProfileLists")
);

const AsyncLoginComponent = asyncComponent(() =>
  import("../views/public/Login")
);
const AsyncForgotPassComponent = asyncComponent(() =>
  import("../views/public/Login/forgotPassword")
);
const AsyncContactUsComponent = asyncComponent(() =>
  import("../views/public/contact")
);
const AsyncSuccesStoriesComponent = asyncComponent(() =>
  import("../views/public/SuccesStories")
);
const AsyncRegisterSuccessComponent = asyncComponent(() =>
  import("../views/public/home/ConfirmationMail")
);
const AsyncRechargeComponent = asyncComponent(() =>
  import("../views/public/recharge")
);

const AsyncTermsComponent = asyncComponent(() =>
  import("../views/public/privacy/privacy")
);

const AsyncPrivacyComponent = asyncComponent(() =>
  import("../views/public/privacy/PrivacyTwo")
);

const AsyncRefundComponent = asyncComponent(() =>
  import("../views/public/privacy/RefundPolicy")
);

const AsyncShippingDelComponent = asyncComponent(() =>
  import("../views/public/privacy/ShippingDelivery")
);

const AsyncAboutUsComponent = asyncComponent(() =>
  import("../views/public/steps/aboutUs")
);

export const PublicRoutsList = [
  {
    exact: true,
    path: PrivacyURL,
    component: AsyncTermsComponent,
  },
  {
    exact: true,
    path: PrivacyTwoURL,
    component: AsyncPrivacyComponent,
  },
  {
    exact: true,
    path: RefundPolicyURL,
    component: AsyncRefundComponent,
  },
  {
    exact: false,
    path: ShippingDelURL,
    component: AsyncShippingDelComponent,
  },
  {
    exact: false,
    path: ContactUsURL,
    component: AsyncContactUsComponent,
  },
  {
    exact: false,
    path: ForgotPasswordURL,
    component: AsyncForgotPassComponent,
  },
  {
    exact: false,
    path: SuccesStoriesURL,
    component: AsyncSuccesStoriesComponent,
  },
  {
    exact: false,
    path: LoginURL,
    component: AsyncLoginComponent,
  },
  {
    exact: false,
    path: RegisterSucceess,
    component: AsyncRegisterSuccessComponent,
  },
  {
    exact: false,
    path: AboutUsURL,
    component: AsyncAboutUsComponent,
  },
  {
    exact: false,
    path: RechargeURL,
    component: AsyncRechargeComponent,
  },
  {
    exact: false,
    path: WatchPublicProfiles,
    component: AsyncProfileListComponent,
  },
  {
    exact: true,
    path: ROOT,
    component: AsyncHome,
  },
];

const PublicRoutes = () => {
  return PublicRoutsList?.map(({ component: Component, path, exact }) => (
    <Route path={path} key={uuidv4()} exact={exact}>
      <Component />
    </Route>
  ));
};

export default PublicRoutes;
