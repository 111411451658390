import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Redirect, Route } from "react-router";
import {
  EditProfileURL,
  FullProfileInfoURL,
  PrivateHomeURL,
  LoginURL,
  ShortListedProfiles,
  ProfileSetting,
  AD_ListOfProfileURL,
  PendingRequests,
} from "../configs";
import AsyncComponent from "../hoc/hocComponent";
import { PublicRoutsList } from "./publicRoutes";

const HomeComponent = AsyncComponent(() => import("../views/private"));
const EditProfileComponent = AsyncComponent(() =>
  import("../views/private/editProfile")
);
const FullProfileInfoComponent = AsyncComponent(() =>
  import("../views/private/ProfileCompleteInfo")
);
const ShortListedProfileComponent = AsyncComponent(() =>
  import("../views/public/shortListed/shortListedProfile")
);

const pendingRequestsProfileComponent = AsyncComponent(() =>
  import("../views/public/PendingRequests/pending")
);
const ProfileSettingComponent = AsyncComponent(() =>
  import("../views/private/profileSetting/mainProfileSetting")
);

// ADMIN
const AD_ListOfProfiles = AsyncComponent(() =>
  import("../views/public/admin/listOfProfiles")
);

const ProtectedRoutsList = [
  {
    exact: true,
    path: PrivateHomeURL,
    component: HomeComponent,
  },
  {
    exact: true,
    path: EditProfileURL,
    component: EditProfileComponent,
  },
  {
    exact: true,
    path: ProfileSetting,
    component: ProfileSettingComponent,
  },
  {
    exact: true,
    path: FullProfileInfoURL,
    component: FullProfileInfoComponent,
  },
  {
    exact: true,
    path: ShortListedProfiles,
    component: ShortListedProfileComponent,
  },
  {
    exact: true,
    path: PendingRequests,
    component: pendingRequestsProfileComponent,
  },
  {
    exact: true,
    path: AD_ListOfProfileURL,
    component: AD_ListOfProfiles,
  },
];

const combinedroutes = [...PublicRoutsList, ...ProtectedRoutsList];
const PrivateRoute = () => {
  const { userIsLoggedIn } = useSelector((state) => state?.user);
  const [isAuth, setIsAuth] = useState(userIsLoggedIn);

  useEffect(() => {
    if (userIsLoggedIn) {
      setIsAuth(userIsLoggedIn);
    }
  }, [userIsLoggedIn]);

  return combinedroutes?.map(({ component: Component, ...rest }, i) => (
    <Route
      key={i}
      {...rest}
      render={(props) =>
        isAuth ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: { LoginURL }, state: { from: props?.location } }}
          />
        )
      }
    />
  ));
};

export default PrivateRoute;
