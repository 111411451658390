import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { tokenAddedHeaders } from "../configs/http-common";

const baseUrl = process.env.REACT_APP_BASE_URL;

// get list of profiles with filter
export const getListOfProfile = createAsyncThunk(
  "/profile/getListOfProfiles",
  async ({ pageNo, filterData }) => {
    try {
      const { data } = await axios.post(
        `${baseUrl}profile/getListOfProfiles?pageNo=${pageNo}&size=10`,
        filterData,
        await tokenAddedHeaders()
      );
      return data.data;
    } catch (error) {
      return error;
    }
  }
);

export const getUserIdInfo = createAsyncThunk(
  "/profile/getProfileInfo",
  async (profileId) => {
    try {
      const { data } = await axios.get(
        `${baseUrl}profile/getProfileInfo?userId=${profileId}`,
        await tokenAddedHeaders()
      );
      return data.data;
    } catch (error) {
      return error;
    }
  }
);

export const getContactDetails = createAsyncThunk(
  "/profile/showContactDetails",
  async (profileId) => {
    try {
      const { data } = await axios.get(
        `${baseUrl}profile/showContactDetails?userId=${profileId}`,
        await tokenAddedHeaders()
      );
      return data.data;
    } catch (error) {
      return error;
    }
  }
);

export const getContactDetailsViewedByMe = createAsyncThunk(
  "/profile/contactDetailsViewedByMe",
  async () => {
    try {
      const { data } = await axios.get(
        `${baseUrl}profile/contactDetailsViewedByMe`,
        await tokenAddedHeaders()
      );
      return data?.data;
      // return data.data;
    } catch (error) {
      return error;
    }
  }
);

export const pendingRequests = createAsyncThunk(
  "/profile/checkPendingRequests",
  async () => {
    try {
      const { data } = await axios.get(
        `${baseUrl}profile/checkPendingRequests`,
        await tokenAddedHeaders()
      );
      return data?.data;
      // return data.data;
    } catch (error) {
      return error;
    }
  }
);

export const resetPassword = createAsyncThunk(
  "/auth/forgot-password",
  async (email) => {
    try {
      const { data } = await axios.get(
        `${baseUrl}auth/forgot-password?email=${email}`
      );
      return data;
    } catch (error) {
      return error;
    }
  }
);

export const getCasteList = createAsyncThunk("/caste", async () => {
  try {
    const { data } = await axios.get(`${baseUrl}caste`);
    return data?.data?.[0];
  } catch (error) {
    return error;
  }
});

export const changeProfileStatus = createAsyncThunk(
  "profile/change-status",
  async (status) => {
    try {
      const { data } = await axios.post(
        `${baseUrl}profile/change-status`,
        { status },
        await tokenAddedHeaders()
      );
      return data?.data;
    } catch (error) {
      return error;
    }
  }
);

export const changeContactRequestStatus = createAsyncThunk(
  "profile/profile-request",
  async (userData) => {
    try {
      const { data } = await axios.post(
        `${baseUrl}profile/profile-request`,
        userData,
        await tokenAddedHeaders()
      );
      return data?.data;
    } catch (error) {
      return error;
    }
  }
);

export const profileSlice = createSlice({
  name: "profile",
  initialState: {
    errorMessage: [],
    contactDetails: {},
    isLoading: true,
    isSuccessOfAPI: false,
    alreadyShownContact: [],
    casteList: [],
  },
  reducers: {
    getContactDetailsAction: async (state, action) => {
      state.contactDetails = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getListOfProfile.fulfilled, (state, action) => {
      state.profile = action.payload || "";
      state.isLoading = false;
    });

    builder.addCase(getContactDetailsViewedByMe.fulfilled, (state, action) => {
      state.alreadyShownContact = action.payload || "";
      state.isLoading = false;
    });

    builder.addCase(pendingRequests.fulfilled, (state, action) => {
      state.alreadyShownContact = action.payload || "";
      state.isLoading = false;
    });

    builder.addCase(getContactDetails.fulfilled, (state, action) => {
      state.contactDetails = action.payload;
    });

    builder.addCase(getCasteList.fulfilled, (state, action) => {
      state.casteList = action.payload;
      state.isLoading = false;
    });
  },
});

export const { getContactDetailsAction } = profileSlice.actions;
export default profileSlice.reducer;
