import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import "../../css/popup.css";

export const PopupMessage = (props) => {
  const { show, handleClose, data } = props;

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title className="last-word">
          Profile information
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row className="profile-info">
            <Col>
              <span className="pp_details">
                <span className="field-name">Name:</span> {data.memberName}
              </span>
            </Col>
          </Row>
          <Row className="profile-info">
            <Col>
              <span className="pp_details">
                <span className="field-name">Education:</span> {data.education}
              </span>
            </Col>
          </Row>
          <Row className="profile-info">
            <Col>
              <span className="pp_details">
                <span className="field-name">Occupation:</span>{" "}
                {data.occupation}
              </span>
            </Col>
          </Row>
          <Row className="profile-info">
            <Col>
              <span className="pp_details">
                <span className="field-name">Email:</span> {data.email}
              </span>
            </Col>
          </Row>
          <Row className="profile-info">
            <Col>
              <span className="pp_details">
                <span className="field-name">Phone:</span> {data.phone}
              </span>
            </Col>
          </Row>
          <Row className="profile-info">
            <Col>
              <span className="pp_details">
                <span className="field-name">Address:</span> {data.addres}
              </span>
            </Col>
          </Row>
          <Row className="profile-info">
            <Col>
              <span className="pp_details">
                <span className="field-name">Net Income:</span> {data.income}
              </span>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
