import ReactGA from "react-ga";

export const initGA = (trackingID) => {
  ReactGA.initialize(trackingID);
};

export const routingTrack = (location) => {
  ReactGA.set({ page: location.pathname });
  ReactGA.pageview(location.pathname);
  // addAnalytics(location.pathname);
};

export const useAnalyticsEventTracker = (category = "Blog category") => {
  const eventTracker = (action = "test action", label = "test label") => {
    ReactGA.event({ category, action, label });
  };
  return eventTracker;
};


export const gaEvent = (info) => {
  ReactGA.event(info);
};
