import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  tokenAddedHeaders,
  headers,
  tokenDocumentAddedHeaders,
} from "../configs/http-common";

const baseUrl = process.env.REACT_APP_BASE_URL;

export const uploadDocument = createAsyncThunk(
  "user/upload-document",
  async (formData, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${baseUrl}user/upload-document`,
        formData,
        await tokenDocumentAddedHeaders()
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const imageSlice = createSlice({
  name: "document",
  initialState: {
    isLoading: true,
    isSuccessOfAPI: false,
    document: {},
  },
  reducers: {
    uploadDocument: (state, action) => {
      state.document = { ...action.payload };
    },
  },
});

export default imageSlice.reducer;
