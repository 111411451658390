import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { tokenAddedHeaders } from "../configs/http-common";

const baseUrl = process?.env?.REACT_APP_BASE_URL;

// get list of profiles with filter
export const getLocality = createAsyncThunk("/locality?`${}", async (query) => {
  try {
    const { data } = await axios.get(`${baseUrl}locality?${query}`, {});
    return data.data;
  } catch (error) {
    return error;
  }
});

// get list of suggestions
export const getLocalitySuggestions = createAsyncThunk(
  "locality/suggestions",
  async (query) => {
    try {
      const { data } = await axios.post(
        `${baseUrl}locality/suggestions`,
        query,
        await tokenAddedHeaders()
      );
      return data.data;
    } catch (error) {
      return error;
    }
  }
);

export const localitySlice = createSlice({
  name: "locality",
  initialState: {
    isLoading: true,
    isSuccessOfAPI: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getLocality.fulfilled, (state, action) => {
      state.locality = action.payload || "";
      state.isLoading = false;
    });
    builder.addCase(getLocalitySuggestions.fulfilled, (state, action) => {
      state.locality = action.payload || "";
      state.isLoading = false;
    });
  },
});

export default localitySlice.reducer;
