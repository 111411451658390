import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const baseUrl = process.env.REACT_APP_BASE_URL;

// get list of profiles with filter
export const getCareer = createAsyncThunk("/career", async () => {
  try {
    const { data } = await axios.get(`${baseUrl}career`, {});
    return data.data;
  } catch (error) {
    return error;
  }
});

export const educationSlice = createSlice({
  name: "eduProf",
  initialState: {
    isLoading: false,
    isSuccessOfAPI: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCareer.fulfilled, (state, action) => {
      state.eduProf = action.payload || "";
    });
  },
});

export default educationSlice.reducer;
