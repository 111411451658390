import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { tokenAddedHeaders, headers } from "../configs/http-common";

const baseUrl = process.env.REACT_APP_BASE_URL;

//  register user first time
export const postRegister = createAsyncThunk(
  "user/postRegister",
  async (registerData) => {
    try {
      const response = await axios.post(
        `${baseUrl}auth`,
        registerData,
        headers
      );
      return response.data;
    } catch (error) {
      return error;
    }
  }
);

export const googleLogin = createAsyncThunk(
  "auth/googleAuth",
  async (token) => {
    try {
      const response = await axios.post(
        `${baseUrl}auth/googleAuth`,
        token,
        headers
      );
      return response.data;
    } catch (error) {
      return error;
    }
  }
);

// login user
export const userLogin = createAsyncThunk(
  "user/userLogin",
  async (loginData) => {
    try {
      const {
        data: { data },
      } = await axios.post(`${baseUrl}auth/login`, loginData, headers);
      return data;
    } catch (error) {
      return error;
    }
  }
);

// get token user information
export const getMyDetails = createAsyncThunk("user/getMyDetails", async () => {
  try {
    const { data } = await axios.get(
      `${baseUrl}user`,
      await tokenAddedHeaders()
    );
    return data.data;
  } catch (error) {
    return error;
  }
});

// Personal Details store
export const storePersonalDetails = createAsyncThunk(
  "user/storePersonalDetails",
  async (personalDetailsData) => {
    try {
      const { data } = await axios.post(
        `${baseUrl}user/personal-details`,
        personalDetailsData,
        await tokenAddedHeaders()
      );
      return data.data;
    } catch (error) {
      return error;
    }
  }
);

// Horoscope Details store
export const storeHoroscopeDetails = createAsyncThunk(
  "user/storeHoroscopeDetails",
  async (horoscopeDetailsData) => {
    try {
      const { data } = await axios.post(
        `${baseUrl}user/horoscope-details`,
        horoscopeDetailsData,
        await tokenAddedHeaders()
      );
      return data.data;
    } catch (error) {
      return error;
    }
  }
);

// Professional Details store
export const storeProfessionalDetails = createAsyncThunk(
  "user/storeProfessionalDetails",
  async (horoscopeDetailsData) => {
    try {
      const { data } = await axios.post(
        `${baseUrl}user/professional-details`,
        horoscopeDetailsData,
        await tokenAddedHeaders()
      );
      return data.data;
    } catch (error) {
      return error;
    }
  }
);

// Professional Details store
export const storeFamilyDetails = createAsyncThunk(
  "user/storeFamilyDetails",
  async (familyDetailsData) => {
    try {
      const { data } = await axios.post(
        `${baseUrl}user/family-details`,
        familyDetailsData,
        await tokenAddedHeaders()
      );
      return data.data;
    } catch (error) {
      return error;
    }
  }
);

// Address Details Store
export const storeAddressDetails = createAsyncThunk(
  "user/storeProfessionalDetails",
  async (addressData) => {
    try {
      const { data } = await axios.post(
        `${baseUrl}user/address-details`,
        addressData,
        await tokenAddedHeaders()
      );
      return data.data;
    } catch (error) {
      return error;
    }
  }
);

// expectations
export const storeExpectationDetails = createAsyncThunk(
  "user/storeExpectationDetails",
  async (expectationData) => {
    try {
      const { data } = await axios.post(
        `${baseUrl}user/expectation-details`,
        expectationData,
        await tokenAddedHeaders()
      );
      return data.data;
    } catch (error) {
      return error;
    }
  }
);

export const userSlice = createSlice({
  name: "user",
  initialState: {
    user: {},
    userIsLoggedIn: false,
    isLoading: false,
    isSuccessOfAPI: false,
  },
  reducers: {
    loginReducer: (state, action) => {
      state.userIsLoggedIn =
        Object.keys(action.payload)?.length > 0 ? true : false;
      state.user = { ...action.payload };
    },
    loginOutReducer: (state, action) => {
      state.userIsLoggedIn = false;
      state.user = {};
    },
    registerReduecer: (state, action) => {
      state.user.registerEmail = { ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMyDetails.fulfilled, (state, action) => {
      state.userIsLoggedIn = true;
      state.user = action.payload;
    });
  },
});

export const { registerReduecer, loginReducer, loginOutReducer } =
  userSlice.actions;
export default userSlice.reducer;
