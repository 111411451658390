import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { razorPayHeaders, tokenAddedHeaders } from "../configs/http-common";

const baseUrl = process.env.REACT_APP_BASE_URL;

// get list of profiles with filter
export const getListOfPlans = createAsyncThunk("/plans", async () => {
  try {
    const { data } = await axios.get(`${baseUrl}plans`, {});
    return data.data;
  } catch (error) {
    return error;
  }
});

export const createOrder = createAsyncThunk("/payment", async (orderData) => {
  try {
    const { data } = await axios.post(
      `${baseUrl}payment`,
      orderData,
      await tokenAddedHeaders()
    );
    return data.data;
  } catch (error) {
    return error;
  }
});

export const paymentSuccess = createAsyncThunk(
  "/payment/success",
  async (orderData) => {
    try {
      const { data } = await axios.post(
        `${baseUrl}payment/success`,
        orderData,
        await razorPayHeaders(orderData?.razorpay_signature)
      );
      return data.data;
    } catch (error) {
      return error;
    }
  }
);

export const planSlice = createSlice({
  name: "plans",
  initialState: {
    isLoading: false,
    isSuccessOfAPI: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getListOfPlans.fulfilled, (state, action) => {
      state.plans = action.payload || "";
    });
  },
});

export default planSlice.reducer;
