import { applyMiddleware, configureStore } from "@reduxjs/toolkit";
import { entitiesReducer, queriesReducer } from "redux-query";
import superagentInterface from "redux-query-interface-superagent";
import getCareerReducer from "./educationSlice";
import uploadDocumentReducer from "./imagesSlice";
import localityReducer from "./localitySlice";
import planReducer from "./planSlice";
import profileReducer from "./profileSlice";
import userReducer from "./userSlice";
import adminReducer from "./adminSlics";

export const getQueries = (state) => state.queries;
export const getEntities = (state) => state.entities;

export default configureStore(
  {
    reducer: {
      // entities: entitiesReducer,
      // queries: queriesReducer,
      user: userReducer,
      profile: profileReducer,
      plans: planReducer,
      locality: localityReducer,
      career: getCareerReducer,
      document: uploadDocumentReducer,
      admin: adminReducer,
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        // serializableCheck: {
        //   ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        // },
        immutableCheck: false,
        serializableCheck: false,
      }),
  },
  applyMiddleware(superagentInterface, getQueries, getEntities)
);
