export const ROOT = "/";

// Public Url's
export const LoginURL = `${ROOT}login`;
export const ForgotPasswordURL = `${ROOT}forgot-password`;
export const ContactUsURL = `${ROOT}contact-us`;
export const PrivacyURL = `${ROOT}terms-and-conditions`;
export const PrivacyTwoURL = `${ROOT}privacy-policy`;
export const SuccesStoriesURL = `${ROOT}succes-stories`;
export const RechargeURL = `${ROOT}pricing`;
export const WatchPublicProfiles = `${ROOT}profile-lists`;
export const RegisterSucceess = `${ROOT}register-success`;
export const AboutUsURL = `${ROOT}about-us`;
export const RefundPolicyURL = `${ROOT}refund-policy`;
export const ShippingDelURL = `${ROOT}shipping-and-delivery-policy`;
export const PrivateHomeURL = `${ROOT}home`;
export const RegisterURL = `${ROOT}register`;
export const ProfileListURL = `${ROOT}profile`;
export const EditProfileURL = `${ROOT}update`;
export const NotificationURL = `${ROOT}notifications`;
export const FullProfileInfoURL = `${ROOT}profile/:profileId`;
export const ShortListedProfiles = `${ROOT}short-listed-profiles`;
export const ProfileSetting = `${ROOT}profile-setting`;
export const PendingRequests = `${ROOT}pending-requests`;

export const AD_ListOfProfileURL = `${ROOT}profiles`;
