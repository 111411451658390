import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { tokenAddedHeaders } from "../configs/http-common";

const baseUrl = process.env.REACT_APP_BASE_URL;

// get list of profiles with filter
export const ad_getAllUserList = createAsyncThunk(
  "/profile/getTotalProfiles",
  async ({ pageNo, filterData }) => {
    try {
      const { data } = await axios.post(
        `${baseUrl}profile/getTotalProfiles?pageNo=${pageNo}&size=50`,
        filterData,
        await tokenAddedHeaders()
      );
      return data.data;
    } catch (error) {
      return error;
    }
  }
);

export const ad_verifyUser = createAsyncThunk(
  "/profile/verifyUser",
  async (data) => {
    const { userId, status } = data;
    try {
      const { data } = await axios.get(
        `${baseUrl}profile/verifyUser?status=${status}&userId=${userId}`,
        await tokenAddedHeaders()
      );
      return data.data;
    } catch (error) {
      return error;
    }
  }
);

export const ad_deleteUser = createAsyncThunk(
  "/profile/delete-user",
  async (data) => {
    const { userId, status } = data;
    try {
      const { data } = await axios.get(
        `${baseUrl}profile/delete-user?status=${status}&userId=${userId}`,
        await tokenAddedHeaders()
      );
      return data.data;
    } catch (error) {
      return error;
    }
  }
);

export const adminSlice = createSlice({
  name: "admin",
  initialState: {
    errorMessage: [],
    isLoading: true,
  },
  reducers: {
    getContactDetailsAction: async (state, action) => {
      state.contactDetails = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(ad_getAllUserList.fulfilled, (state, action) => {
      state.profile = action.payload || "";
      state.isLoading = false;
    });
  },
});

export const { getContactDetailsAction } = adminSlice.actions;
export default adminSlice.reducer;
