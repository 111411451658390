import React from "react";
import Spinner from "react-spinkit";

const AsyncComponent = (importComponent) => {
  class AsyncComponent extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        component: null,
        loading: true, // Track loading state
      };
      this.abortController = new AbortController(); // Create an AbortController
    }

    async componentDidMount() {
      try {
        const { signal } = this.abortController; // Get the signal from the controller
        const { default: component } = await importComponent();
        if (!signal.aborted) {
          // Check if the request was aborted before setting state
          this.setState({ component, loading: false });
        }
      } catch (error) {
        console.error("Error loading component:", error);
        this.setState({ loading: false });
      }
    }

    componentWillUnmount() {
      this.abortController.abort(); // Cancel the fetch request when component unmounts
    }

    render() {
      const { component: StateComponent, loading } = this.state;
      return loading ? (
        <div className="spinner-div">
          <Spinner name="double-bounce" />
        </div>
      ) : StateComponent ? (
        <StateComponent {...this.props} />
      ) : null; // Render null if component is not loaded
    }
  }
  return AsyncComponent;
};

export default AsyncComponent;
