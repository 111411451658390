import { Helmet } from "react-helmet";

export const HelmetComponent = ({ pageName, path }) => {
  const url = `https://ashtvivah.com`;
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{pageName}</title>
      <link rel="canonical" href={url} />
    </Helmet>
  );
};
