import React, { useEffect } from "react";
import { ViewComponent } from "./views";
import { BrowserRouter } from "react-router-dom";
import { getMyDetails, loginReducer } from "./redux/userSlice";
import { useDispatch } from "react-redux";
import { positions, Provider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";
import { HelmetComponent } from "./views/helpers";

import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import "./css/home.css";
import "./css/profileList.css";

// require("dotenv").config();

const options = {
  timeout: 5000,
  position: positions.MIDDLE,
};

function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    const fetchData = async () => {
      const getUserDetails =
        localStorage.getItem("user") && (await dispatch(getMyDetails()));
      const userId = getUserDetails?.payload?.userId;
      if (!userId) {
        localStorage.removeItem("user");
        dispatch(loginReducer({}));
      } else {
        dispatch(loginReducer(getUserDetails?.payload));
      }
    };
    fetchData();
  });

  return (
    <BrowserRouter>
      <HelmetComponent pageName="ashtvivah.com" />
      <Provider template={AlertTemplate} {...options}>
        <ViewComponent />
      </Provider>
    </BrowserRouter>
  );
}

export default App;
