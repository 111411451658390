import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import {
  Navbar,
  Container,
  Nav,
  NavDropdown,
  Button,
  Image,
  ListGroup,
} from "react-bootstrap";
import {
  ROOT,
  EditProfileURL,
  NotificationURL,
  ContactUsURL,
  SuccesStoriesURL,
  RechargeURL,
  WatchPublicProfiles,
  LoginURL,
  PrivacyURL,
  ShortListedProfiles,
  ProfileSetting,
  PendingRequests,
  AD_ListOfProfileURL,
} from "../configs";
import { useDispatch, useSelector } from "react-redux";
import "../css/nav.css";
import { getLocality } from "../redux/localitySlice";
import { getCareer } from "../redux/educationSlice";
import { loginOutReducer } from "../redux/userSlice";
import { gaEvent } from "../utils/useAnalyticsEventTracker";
import {
  FaClock,
  FaUserCog,
  FaBell,
  FaRegUser,
  FaClipboardList,
  FaSignOutAlt,
  FaUser,
} from "react-icons/fa";
import logo from "../images/ashtavivah/SVG/WEB.svg";
import { pendingRequests } from "../redux/profileSlice";
import { useMediaQuery } from "react-responsive";
const dropDownStaticData = [
  { linkURL: EditProfileURL, value: "Complete Profile", icon: <FaRegUser /> },
  {
    linkURL: ShortListedProfiles,
    value: "Shortlisted Profiles",
    icon: <FaClipboardList />,
  },
  {
    linkURL: PendingRequests,
    value: "Pending Requests",
    icon: <FaClock />,
  },
  { linkURL: ProfileSetting, value: "Profile Settings", icon: <FaUserCog /> },
];

const MobileUserLoggedInList = (props) => {
  const { userData, setExpanded, signOut, onClickUserIcon } = props;
  const dropdownTitle = `Hi ${userData?.userName} ${userData.userId}`;
  const history = useHistory();
  return (
    <>
      <ListGroup className="bg-light">
        {dropDownStaticData?.map((item) => {
          return (
            <ListGroup.Item
              className="bg-light"
              key={item?.linkURL}
              onClick={() => {
                history.push(item?.linkURL ?? "/home");
                onClickUserIcon(false);
              }}
            >
              {item.icon} {"  "}
              {item?.value}
            </ListGroup.Item>
          );
        })}
        <Button onClick={signOut} variant="danger" size="sm">
          <FaSignOutAlt /> Sign out
        </Button>
      </ListGroup>
    </>
  );
};

const NavDropdownComponent = (props) => {
  const { userData, setExpanded, signOut } = props;
  const dropdownTitle = `Hi ${userData?.userName} ${userData.userId}`;

  return (
    <>
      <NavDropdown
        title={dropdownTitle.toUpperCase()}
        id="basic-nav-dropdown"
        className="bg-light"
      >
        {dropDownStaticData?.map((item) => {
          return (
            <NavDropdown.Item
              className="p-2"
              key={item?.linkURL}
              as={Link}
              to={item?.linkURL ?? "/home"}
              onClick={() => setExpanded(false)}
            >
              {item.icon} {"  "}
              {item?.value}
            </NavDropdown.Item>
          );
        })}
        <NavDropdown.Item>
          <div className="d-grid gap-2">
            <Button onClick={signOut} variant="danger" size="sm">
              <FaSignOutAlt /> Sign out
            </Button>
          </div>
        </NavDropdown.Item>
      </NavDropdown>
    </>
  );
};

export const NavbarComponent = () => {
  const [expanded, setExpanded] = useState(false);
  const [isClickUserIcon, onClickUserIcon] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const { user, userIsLoggedIn, currentSelectedPlan } = useSelector(
    (state) => state.user
  );

  const { profile } = useSelector((state) => state);
  const [notificationCount, setNotificationCount] = useState(0);
  const userName = user?.userRegisterDetails?.firstName || "";
  const { userId } = user;
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const signOut = () => {
    localStorage.clear("user");
    sessionStorage.clear();

    gaEvent({
      category: "Click",
      action: "Sign Out",
      transport: "beacon",
    });
    dispatch(loginOutReducer());
    history.push(LoginURL);
  };

  useEffect(() => {
    dispatch(getLocality("state=MAHARASHTRA"));
    dispatch(getCareer());
    if (currentSelectedPlan?.length > 0) dispatch(pendingRequests());
    if (profile?.alreadyShownContact.length)
      setNotificationCount(
        profile?.alreadyShownContact?.filter((i) => i.approved)?.length
      );
  }, [currentSelectedPlan, profile]);

  const viewProfileName = `Search ${!userIsLoggedIn ? "Free" : ""} Profiles`;
  const userType = user?.userType;

  let mainNavLink =
    userType === "COMMON_USER" || userType === undefined
      ? [
          { linkURL: WatchPublicProfiles, value: viewProfileName },
          { linkURL: SuccesStoriesURL, value: "Success Stories" },
          // { linkURL: ContactUsURL, value: "Contact us" },
          // { linkURL: PrivacyURL, value: "Terms & Conditions" },
        ]
      : [
          { linkURL: WatchPublicProfiles, value: viewProfileName },
          { linkURL: AD_ListOfProfileURL, value: "Registered Users" },
        ];
  if (!user.currentSelectedPlan?.length > 0) {
    mainNavLink.push({ linkURL: RechargeURL, value: "Pricing" });
  }

  return (
    <Navbar bg="light" expand="lg" expanded={expanded} fixed="top">
      <Container>
        <Navbar.Brand as={Link} to={ROOT}>
          <Image
            src={logo}
            alt="ashtvivah-Marathi matrimony website"
            className="ashtvivah-logo"
          />
        </Navbar.Brand>
        {isMobile && userIsLoggedIn && (
          <div>
            <FaUser
              className="user-icon"
              style={{
                marginRight: "1rem",
                fontSize: "1.5rem",
                position: "relative",
              }}
              onClick={() => {
                onClickUserIcon(!isClickUserIcon);
                setExpanded(false);
              }}
            />
            {isClickUserIcon && (
              <div className="userClickIconList bg-light">
                <MobileUserLoggedInList
                  userData={{ userName, userId }}
                  signOut={signOut}
                  setExpanded={setExpanded}
                  onClickUserIcon={onClickUserIcon}
                />
              </div>
            )}
          </div>
        )}
        {isMobile && !userIsLoggedIn && (
          <div
            md={{ offset: 2 }}
            style={{ marginRight: "5px", fontSize: "1.5rem" }}
          >
            <Button
              variant="primary"
              as={Link}
              to="/login"
              onClick={() => setExpanded(false)}
            >
              Log in
            </Button>
          </div>
        )}
        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          onClick={() => {
            setExpanded(expanded ? false : "expanded");
            onClickUserIcon(false);
          }}
        />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            {mainNavLink?.map((item) => {
              return (
                <Nav.Link
                  key={item?.linkURL}
                  as={Link}
                  to={item?.linkURL ?? "/"}
                  className="navbarTabColor"
                  onClick={() => setExpanded(false)}
                >
                  {item?.value}
                </Nav.Link>
              );
            })}
          </Nav>
          {!isMobile && !userIsLoggedIn && (
            <div md={{ offset: 2 }}>
              <Button
                variant="primary"
                as={Link}
                to="/login"
                onClick={() => setExpanded(false)}
              >
                Log in
              </Button>
            </div>
          )}
          {userIsLoggedIn && !isMobile && (
            <>
              <Nav className="ml-auto position-relative">
                <Nav.Link
                  as={Link}
                  to={PendingRequests}
                  onClick={() => setExpanded(false)}
                >
                  <FaBell />
                  <span className="badge badge-primary position-absolute translate-middle ">
                    {notificationCount}
                  </span>
                </Nav.Link>
              </Nav>
              <NavDropdownComponent
                userData={{ userName, userId }}
                signOut={signOut}
                setExpanded={setExpanded}
              />
            </>
          )}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};
